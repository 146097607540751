import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from "./DatePicker";
import MultiSelect from "./MultiSelect";
import { Chart } from "./Chart";
import { api } from "../api/api";
import dayjs from "dayjs";
import { useDebounce } from "../utils/debounce";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { TIMEZONE } from "../config/entrypoint";


const Dashboard = () => {
	const [appCount, setAppCount] = useState({})
	const [chartData, setChartData] = useState({})
	const [utmSources, setUtmSources] = useState([])
	const [searchUtm, setSearchUtm] = useState('')
	const [chosenUtm, setChosenUtm] = useState([])
	const [loading, setLoading] = useState(true)
	const [date, setDate] = useState({
		// get last week date
		dateFrom: +new Date(dayjs().subtract(6, 'days').format(`YYYY-MM-DDT00:00:00${TIMEZONE}`)),
		dateTo: +new Date(dayjs().format(`YYYY-MM-DDT00:00:00${TIMEZONE}`))
	})

	const debouncedSearchUtm = useDebounce(searchUtm)

	const handleChangeDateFrom = useCallback((newValue) => {
		const dateFrom = +new Date(`${newValue.format('YYYY-MM-DD')}T00:00:00${TIMEZONE}`)
		setDate({ ...date, dateFrom })
	}, [date])

	const handleChangeDateTo = useCallback((newValue) => {
		const dateTo = +new Date(`${newValue.format('YYYY-MM-DD')}T00:00:00${TIMEZONE}`)
		setDate({ ...date, dateTo })
	}, [date])

	const handleClickFetchBtn = () => {
		setLoading(true);
	}

	useEffect(() => {
		const params = {
			...date,
			dateTo: date.dateTo + 86399000,
		}
		api.getApplicationsCount(params)
			.then(({ data }) => {
				setAppCount({
					filled: data.data.filled,
					total: data.data.filled + data.data.unFilled,
					unFilled: data.data.unFilled
				})
			})
			.catch(e => console.log(e))
	}, [date])

	useEffect(() => {
		if (loading) {
			setChartData({})
			const params = {
				...date,
				dateTo: date.dateTo + 86399000,
				hasOverdueCredit: 1
			}

			api.getApplicationStatistic(api.objectToQuery(params))
				.then(({ data }) => {
					setChartData(prev => ({ ...prev, 'all': data.data }))
					setLoading(false)
				})
				.catch(e => console.log(e))

			if (chosenUtm.length > 0) {
				chosenUtm.forEach(async value => {
					const utmParams = {
						...date,
						hasOverdueCredit: 1,
						utmSources: [value]
					}
					const { data } = await api.getApplicationStatistic(api.objectToQuery(utmParams))
					setChartData(prev => ({ ...prev, [value]: data.data }))
				})
			}

		}

	}, [loading])


	useEffect(() => {
		const params = {
			...date,
			utmSource: `${debouncedSearchUtm}*`,
			hasOverdueCredit: 1
		}
		api.getUtmSource(api.objectToQuery(params))
			.then(({ data }) => {
				setUtmSources(data.data.map(utm => utm.key))
			})
	}, [debouncedSearchUtm, date])

	useEffect(() => {
		setChosenUtm([])
	}, [date])


	return (
		<div>
			<Grid container spacing={2} mt={1}>
				<Grid item xs={12}>
					<Typography>
						Total: {appCount.total}
					</Typography>
					<Typography>
						Unfilled: {appCount.unFilled}
					</Typography>
					<Typography>
						Full: {appCount.filled}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack spacing={3}>
							<DatePicker label={'Date from'} value={date.dateFrom} minDate={'01/01/2023'}
										onHandleChange={handleChangeDateFrom} />
						</Stack>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={3}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack spacing={3}>
							<DatePicker label={'Date to'} value={date.dateTo} minDate={date.dateFrom}
										onHandleChange={handleChangeDateTo} />
						</Stack>
					</LocalizationProvider>
				</Grid>
				<Box width="100%" />
				<Grid item xs={6}>
					<MultiSelect chosenUtm={chosenUtm} setChosenUtm={setChosenUtm} setSearchUtm={setSearchUtm}
								 utmSources={utmSources} />
				</Grid>
				<Box width="100%" />
				<Grid item xs={2} display='flex' alignItems='center'>
					<LoadingButton
						size="small"
						onClick={handleClickFetchBtn}
						loading={loading}
						loadingIndicator="Loading…"
						variant="outlined"
					>
						<span>Fetch data</span>
					</LoadingButton>
				</Grid>

				<Grid item xs={12} position='relative'>
					<Chart chartData={chartData} loading={loading} />
					{loading &&
						<Box sx={{
							display: 'flex',
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)'
						}}>
							<CircularProgress />
						</Box>
					}
				</Grid>
			</Grid>
		</div>
	);
};

export default Dashboard;