import { ExportButton, TopToolbar } from "react-admin";
import { FieldGuesser, ListGuesser } from "@api-platform/admin";

const CustomActions = () => {
	return (
		<TopToolbar>
			<ExportButton maxResults={10000} label="Export" />
		</TopToolbar>
	)
}


const VisitsList = () => {
	return (
		<>
			<ListGuesser actions={<CustomActions />}>
				<FieldGuesser source={"utmSource"} sortable={false}/>
				<FieldGuesser source={"postbackSent"} sortable={false}/>
				<FieldGuesser source={"sentAt"} sortable={false}/>
				<FieldGuesser source={"applicationId"} sortable={false}/>
				<FieldGuesser source={"response"} sortable={false}/>
				<FieldGuesser source={"createdAt"} />
				<FieldGuesser source={"updatedAt"} sortable={false}/>
			</ListGuesser>
		</>

	)
}

export default VisitsList;