import {ENTRYPOINT} from './config/entrypoint';

export default {
    // @ts-ignore
    login: ({username, password}) => {
        const request = new Request(`${ENTRYPOINT}/login.json`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((user) => {
                localStorage.setItem('user', JSON.stringify(user));
            });
    }, logout: () => {
        localStorage.removeItem('user');
        return Promise.resolve();
    }, checkAuth: () => {
        try {
            if (!localStorage.getItem('user')) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    }, checkError: (err: { status: any; response: { status: any; }; }) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem('user');
            return Promise.reject();
        }
        return Promise.resolve();
    }, getPermissions: () => Promise.resolve(localStorage.getItem("user").roles),
};