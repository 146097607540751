import { CreateButton, ExportButton, TopToolbar } from "react-admin";
import { FieldGuesser, ListGuesser } from "@api-platform/admin";

const CustomActions = () => {
	return (
		<TopToolbar>
			<CreateButton />
			<ExportButton maxResults={10000} label="Export" />
		</TopToolbar>
	)
}


const VisitsList = () => {
	return (
		<>
			<ListGuesser hasCreate actions={<CustomActions />}>
				<FieldGuesser source={"visitHash"} sortable={false} />
				<FieldGuesser source={"clientHash"} sortable={false} />
				<FieldGuesser source={"ip"} sortable={false} />
				<FieldGuesser source={"requestDetails"} sortable={false} />
				<FieldGuesser source={"country"} sortable={false} />
				<FieldGuesser source={"path"} sortable={false} />
				<FieldGuesser source={"visitStartedAt"} />
				<FieldGuesser source={"visitEndedAt"} />
				<FieldGuesser source={"visitDuration"} sortable={false} />
			</ListGuesser>
		</>

	)
}

export default VisitsList;