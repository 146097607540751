import { Create, SimpleForm, TextInput } from "react-admin";

const ConnectorsCreate = (props) => {
	const textareaStyle = {width: '190px'}
	return (<Create {...props}>
			<SimpleForm>
				<TextInput source="name" />
				<TextInput source="method" />
				<TextInput source="apiEndpoint" />
				<TextInput style={textareaStyle} source="headers" multiline/>
				<TextInput style={textareaStyle} source="query" multiline/>
				<TextInput source="bodyEncode" />
			</SimpleForm>
		</Create>
	)
}

export default ConnectorsCreate