import axios from "axios";
import { ENTRYPOINT } from "../config/entrypoint";


const instance = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    return axios.create({
        headers: { "X-TEMP-API-Key": user && user.token ? user.token : "" },
        withCredentials: 'include',
        baseURL: ENTRYPOINT
    })
}

export const api = {
    getApplicationsCount({dateFrom, dateTo}) {
        return instance().get(`api/applications/count/detailed?createdFrom=${dateFrom}&createdTo=${dateTo}`)
    },
    getApplicationStatistic(params){
        return instance().get(`api/applications/get/application_statistic?${params}`)
    },
    getUtmSource(params) {
        return instance().get(`api/applications/get/utm_source?${params}`)
    },
    objectToQuery(obj, pref = []) {

        let res = [];
        for (let k in obj) {

            if (obj.hasOwnProperty(k)) {

                if (typeof obj[k] === "object" && obj[k] !== null) {

                    res.push(this.objectToQuery(obj[k], [...pref, k]));

                } else if (typeof obj[k] !== "undefined") {

                    let prefKey = [...pref, k].map((e, i) => i === 0 ? e : `[${e}]`).join("");

                    res.push(`${encodeURIComponent(prefKey)}=${encodeURIComponent(obj[k])}`);

                }

            }

        }

        return res.join("&");
    }
}