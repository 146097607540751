import { useState } from "react";
import {
	fetchHydra as baseFetchHydra,
	HydraAdmin,
	hydraDataProvider as baseHydraDataProvider,
	ResourceGuesser,
	useIntrospection
} from "@api-platform/admin";
import { CustomRoutes } from "react-admin";
import { Navigate, Route } from "react-router-dom";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

import Dashboard from "./components/Dashboard";
import ApplicationList from "./components/ApplicationList";
import VisitsList from "./components/VisitsList";
import PostbackLogs from "./components/PostbackLogs";
import ConnectorsCreate from "./components/PostbackCreate";

import authProvider from './authProvider';
import { ENTRYPOINT } from "./config/entrypoint";

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaidIcon from '@mui/icons-material/Paid';
import ApiIcon from '@mui/icons-material/Api';
import BadgeIcon from '@mui/icons-material/Badge';
import TourIcon from '@mui/icons-material/Tour';

// Replace with your own API entrypoint
// For instance if https://example.com/api/books is the path to the collection of book resources, then the entrypoint is https://example.com/api
const fetchHydra = (url, options = {}) => {
	const user = JSON.parse(localStorage.getItem("user"));

	return baseFetchHydra(url, {
		...options,
		credentials: 'include',
		headers: { "X-TEMP-API-Key": user && user.token ? user.token : "" }
	});
}
const dataProvider = (setRedirectToLogin) => baseHydraDataProvider({
	entrypoint: ENTRYPOINT + "/api",
	httpClient: fetchHydra,
	apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
});
const RedirectToLogin = () => {
	const introspect = useIntrospection();

	if (localStorage.getItem("user")) {
		introspect();
		return <></>;
	}
	return <Navigate to="/login" />;
};

const apiDocumentationParser = (setRedirectToLogin) => async () => {
	try {
		setRedirectToLogin(false);
		const user = JSON.parse(localStorage.getItem("user"));
		return await parseHydraDocumentation(ENTRYPOINT + "/api", {
			credentials: 'include',
			headers: { "X-TEMP-API-Key": user && user.token ? user.token : "" }
		});
	} catch (result) {
		const { api, response, status } = result;
		if (status !== 401 || !response) {
			throw result;
		}

		// Prevent infinite loop if the token is expired
		localStorage.removeItem("user");

		setRedirectToLogin(true);

		return {
			api,
			response,
			status,
		};
	}
};


const App = () => {
	const [redirectToLogin, setRedirectToLogin] = useState(false);

	return (<>
		<HydraAdmin dashboard={Dashboard} requireAuth={true} dataProvider={dataProvider(setRedirectToLogin)}
					authProvider={authProvider}
					entrypoint={`${ENTRYPOINT}/api`}>
			<CustomRoutes>
				{redirectToLogin ? <Route path="/" element={<RedirectToLogin />} /> : null}
			</CustomRoutes>
			<ResourceGuesser name="offers" icon={AttachMoneyIcon} />
			<ResourceGuesser name="api_clients" icon={ApiIcon} />
			<ResourceGuesser name="applications" list={ApplicationList} icon={BadgeIcon} />
			<ResourceGuesser name="visits" list={VisitsList} icon={TourIcon} />
			<ResourceGuesser name="lead_types" icon={PaidIcon} />
			<ResourceGuesser name="postbacks" icon={BadgeIcon} />
			<ResourceGuesser name="postback_logs" list={PostbackLogs} icon={BadgeIcon} />
			<ResourceGuesser name="redirect_rules" icon={BadgeIcon} />
			<ResourceGuesser name="connectors" create={ConnectorsCreate} icon={BadgeIcon} />
			<ResourceGuesser name="connector_settings" icon={BadgeIcon} />
		</HydraAdmin>
	</>)
}

export default App