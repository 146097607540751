import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Application information',
        },
        colors: {
            forceOverride: true
        }
    },
};


export const Chart = React.memo(({ chartData, loading }) => {
    const data = []
    const labels = Object.values(chartData).length > 0 && Object.values(chartData)[0].map(item => item.key_as_string.split(' ')[0])
    Object.entries(chartData).forEach(arr => {
        data.push({
            label: arr[0],
            data: arr[1].map(i => i.doc_count),
        })
    })

    const preparedData = {
        labels: labels,
        datasets: data,
    };


    return <Line style={{filter: loading ? 'blur(2px)' : 'blur(0px)'}} options={options} data={preparedData} />;
})