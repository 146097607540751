import React from 'react';
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";



const DatePicker = React.memo(({ label, value, onHandleChange, minDate }) => {
    return (
        <DesktopDatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            minDate={minDate}
            maxDate={Date.now()}
            value={value}
            onChange={onHandleChange}
            renderInput={(params) => <TextField {...params} />}
        />
    );
})

export default DatePicker;