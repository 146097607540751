import { CreateButton, DateInput, ExportButton, FilterButton, TopToolbar } from "react-admin";
import { FieldGuesser, ListGuesser } from "@api-platform/admin";


const dateFilters = [
	<DateInput
		label="Date from"
		source="createdAt[after]"
	/>,
	<DateInput
		label="Date to"
		source="createdAt[before]"
	/>
]

const CustomActions = () => {
	return (
		<TopToolbar>
			<FilterButton filters={dateFilters} />
			<CreateButton />
			<ExportButton maxResults={10000} label="Export" />
		</TopToolbar>
	)
}

const ApplicationList = () => {
	return (
		<>
			<ListGuesser hasCreate
						 actions={<CustomActions />}
						 filters={dateFilters}
						 sort={{ field: 'createdAt', order: 'DESC' }}>
				<FieldGuesser source={"applicationId"} sortable={false} />
				<FieldGuesser source={"status"} sortable={false} />
				<FieldGuesser source={"visitHash"} sortable={false} />
				<FieldGuesser source={"phone"} sortable={false} />
				<FieldGuesser source={"phoneStatus"} sortable={false} />
				<FieldGuesser source={"lastName"} sortable={false} />
				<FieldGuesser source={"firstName"} sortable={false} />
				<FieldGuesser source={"email"} sortable={false} />
				<FieldGuesser source={"birthday"} sortable={false} />
				<FieldGuesser source={"activeCredit"} sortable={false} />
				<FieldGuesser source={"overdueCredit"} sortable={false} />
				<FieldGuesser source={"inn"} sortable={false} />
				<FieldGuesser source={"innStatus"} sortable={false} />
				<FieldGuesser source={"utmSource"} sortable={false} />
				<FieldGuesser source={"createdAt"} />
				<FieldGuesser source={"updatedAt"} sortable={false} />
				<FieldGuesser source={"smsCode"} sortable={false} />
				<FieldGuesser source={"verificationHash"} sortable={false} />
			</ListGuesser>
		</>

	)
}

export default ApplicationList;